@import '../../styles/variables.scss';
@import '../../styles/layout.scss';

.outer__container {
  position: fixed;
  width: 100%;
  z-index: 1000;
}


.container {

  padding: 0 #{$mobile-padding};
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include respond-to('desktop') {
      width: 50%;
      margin: 0 auto;
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22.5px;

      svg {
        height: 22.5px;
        // fill: var(--text-color);
        transition: fill #{$theme-duration} ease-out;
      }

      img {
        width: auto;
        height: 37px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      & > * {
        display: flex;
        align-items: center;
        font-size: 16px;
      }

      .hamburger {
        width: 30px;
        height: auto;
        margin-left: 10px;
      }
    }
  }
}


.menu {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
  z-index: 1000;

  .content {
    display: flex;
    justify-content: flex-end;

    ul {
      margin-top: 100px;
      list-style: none;

      li {
        font-size: 30px;
        text-align: right;

        /*         &:first-of-type {
          text-decoration: line-through;
        } */

        & > * {
          font-size: inherit;
        }
      }
    }
  }
}
