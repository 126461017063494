.event__banner {
    width: 100%;
    height: 60px;
    padding: 0 2rem;

    color: #fff;
    background-color: #0057FF ;

    @media (max-width: 768px) {
        padding: 0 1rem;
        font-size: 12px;
    }
}

.desktop__link {
    display: none;

    @media (min-width: 769px) {
        display: inline-block;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    a {
        color: #fff;
        text-decoration: underline;
    }

    .content__button {
        text-decoration: none;

        padding: 0.25rem 1rem;
        color: #fff;
        background-color: #000;

        @media (max-width: 768px) {
            min-width: fit-content;

            font-size: 14px;
            padding: 0.25rem 0.5rem;
        }
    }
}
